import { graphql } from 'gatsby';
import React from 'react';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';

import Container from '@components/Container';
import Grid from '@components/Grid';
import TextHero from '@components/TextHero';
import UspList from '@components/UspList/UspList';

import Card from '@src/components/Card';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import {
  ContentfulCard,
  ContentfulQuestion,
  ContentfulTextList,
} from '../../graphql-types';

const Pay = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageContent,
    },
  } = data;

  const uspList = pageContent[0] as ContentfulTextList;

  const portalCard1 = pageContent[1] as ContentfulCard;
  const portalCard2 = pageContent[2] as ContentfulCard;
  const portalCard3 = pageContent[3] as ContentfulCard;
  const rockerPayImage1 = useContentfulImage(portalCard1.backgroundImage);
  const rockerPayImage2 = useContentfulImage(portalCard2.backgroundImage);
  const rockerPayImage3 = useContentfulImage(portalCard3.backgroundImage);

  const { questions, questionsHeading } = data.contentfulPage;
  const headLine = data.contentfulPage.headLine;

  return (
    <Layout
      path="/lan"
      theme="soft"
      questions={questions as ContentfulQuestion[]}
      questionsHeading={questionsHeading}
    >
      <SEO title={headLine} />
      <Container style={{ paddingBottom: 68, paddingTop: 32 }}>
        <Grid>
          <div style={{ maxWidth: 448 }}>
            <TextHero
              leftAligned
              size="medium"
              title={pageHeader}
              subtitle={pageHeaderDescription}
              style={{ paddingTop: 32, paddingBottom: 32 }}
            >
              <UspList items={uspList.advancedTextListItems} />
            </TextHero>
          </div>
          <Card
            title={portalCard1.title}
            text={portalCard1.subtitle}
            ctaHref={portalCard1.linkPath}
            cta={portalCard1.linkText}
            backdropImage={rockerPayImage1}
            theme="purple"
          />
          <Card
            title={portalCard2.title}
            text={portalCard2.subtitle}
            ctaHref={portalCard2.linkPath}
            cta={portalCard2.linkText}
            backdropImage={rockerPayImage2}
            theme="purple"
          />
          <Card
            title={portalCard3.title}
            text={portalCard3.subtitle}
            ctaHref={portalCard3.linkPath}
            cta={portalCard3.linkText}
            backdropImage={rockerPayImage3}
            theme="purple"
          />
        </Grid>
      </Container>
    </Layout>
  );
};

export default Pay;

export const query = graphql`
  query Portal($locale: String) {
    contentfulPage(pageUrl: { eq: "/portal" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      questionsHeading
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCard {
          additionalLinkPath
          additionalLinkText
          linkPath
          linkText
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
        }
        ... on ContentfulCardsSectionComponent {
          entryTitle
          card {
            additionalLinkPath
            additionalLinkText
            subtitle
            title
            linkText
            linkPath
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          heading
          textContent {
            textContent
          }
          ctaText
          ctaHref
          extraContent {
            ... on ContentfulTextList {
              advancedTextListItems {
                text
                icon
              }
              listName
            }
          }
          questions {
            answer {
              answer
            }
            question
          }
        }
        ... on ContentfulGenericList {
          name
          items {
            icon {
              file {
                url
              }
              title
            }
            extraText
            longText {
              longText
            }
            title
            text
          }
        }
        ... on ContentfulTextList {
          id
          listName
          advancedTextListItems {
            icon
            text
          }
        }
      }
      pageContactHeader
      customContactMethods {
        name
        methods {
          link
          linkText
          title
          extraLinkText
          extraLink
          icon {
            file {
              url
            }
          }
          description {
            description
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
